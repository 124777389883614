import React from "react"
import PageImage from './page-image'
import SEO from "../components/seo"

const IndexPage = () => (
  <div>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <PageImage />
  </div>
)

export default IndexPage

